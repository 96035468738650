// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-js": () => import("./../../src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookies-js": () => import("./../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-ecommerce-thankyou-js": () => import("./../../src/pages/ecommerce-thankyou.js" /* webpackChunkName: "component---src-pages-ecommerce-thankyou-js" */),
  "component---src-pages-engagement-js": () => import("./../../src/pages/engagement.js" /* webpackChunkName: "component---src-pages-engagement-js" */),
  "component---src-pages-gdpr-js": () => import("./../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-headless-cms-sandbox-js": () => import("./../../src/pages/headless-cms-sandbox.js" /* webpackChunkName: "component---src-pages-headless-cms-sandbox-js" */),
  "component---src-pages-headless-ecommerce-js": () => import("./../../src/pages/headless-ecommerce.js" /* webpackChunkName: "component---src-pages-headless-ecommerce-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-is-webriq-glue-for-you-js": () => import("./../../src/pages/is-webriq-glue-for-you.js" /* webpackChunkName: "component---src-pages-is-webriq-glue-for-you-js" */),
  "component---src-pages-oldterms-js": () => import("./../../src/pages/oldterms.js" /* webpackChunkName: "component---src-pages-oldterms-js" */),
  "component---src-pages-pricing-js": () => import("./../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-seo-js": () => import("./../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-terms-js": () => import("./../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-test-js": () => import("./../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-thank-you-agency-js": () => import("./../../src/pages/thank-you-agency.js" /* webpackChunkName: "component---src-pages-thank-you-agency-js" */),
  "component---src-templates-blog-post-js": () => import("./../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-categories-js": () => import("./../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-insight-post-js": () => import("./../../src/templates/insight-post.js" /* webpackChunkName: "component---src-templates-insight-post-js" */),
  "component---src-templates-user-js": () => import("./../../src/templates/user.js" /* webpackChunkName: "component---src-templates-user-js" */)
}

